import {
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useWindowSize } from '../../../hooks/use-window.hook';
import { appStoreContext } from '../../../stores/context.store';
import { Button } from '../../components/button';
import './profile.styles.scss';

export const Profile: FC = observer(() => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const { user_id } = useParams();
  const [iFrameKey] = useState<number>(0);

  const AppStore = useContext(appStoreContext);
  const { token, s3Token, loading } = AppStore.pretender;

  const { height } = useWindowSize();
  const frameHeight = height - 32;

  const sendToken = useCallback((): void => {
    const target = iframeRef.current;
    const key = window.btoa(AppStore.config.headerKey);

    if (target?.contentWindow && token) {
      target.contentWindow.postMessage(
        {
          s3Token,
          token,
          key,
        },
        '*',
      );
    }
  }, [AppStore.config.headerKey, s3Token, token]);

  useEffect(() => {
    AppStore.pretender.pretend(user_id);

    return () => {
      AppStore.pretender.reset();
    };
  }, []);

  return (
    <div className="profile">
      <div
        className="profile__controls"
      >
        <Button
          className="profile__controls__button"
          size="sm"
          variant="white"
          disabled={loading && !!iframeRef.current}
          onClick={sendToken}
        >
          Выполнить вход
        </Button>
      </div>
      <iframe
        className="profile__iframe"
        title="unione"
        key={iFrameKey}
        src={AppStore.config.profileURI}
        width="100%"
        height={frameHeight}
        ref={iframeRef}
        onLoad={sendToken}
      />
    </div>
  );
});
