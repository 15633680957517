import { FC, Fragment, useContext, useMemo } from 'react';
import { IUser } from '../../../models/entities/user.models';
import { appStoreContext } from '../../../stores/context.store';
import { Pagination } from '../../components/pagination';
import {
  ITableColumn,
  Table,
} from '../../components/table';
import { UsersListColumns } from './users-list.columns';
import { UsersListEmpty } from './users-list.empty';
import { ITableUsersData, IUserListTable } from './users-list.models';
import 'rc-dropdown/assets/index.css';
import './users-list.styles.scss';

export const UsersTable: FC<IUserListTable> = (props) => {
  const AppStore = useContext(appStoreContext);

  const columns = useMemo<ITableColumn<ITableUsersData>[]>(
    () => UsersListColumns,
    [],
  );

  const onSmsClick = (user: IUser): void => {
    AppStore.smsCode.getSmsCode(user.id, user.phone);
  };

  const onEmailClick = (id: string): void => {
    AppStore.emailCode.getEmailCode(id);
  };

  const TypedTable = Table<ITableUsersData>;
  const tableUsersData: ITableUsersData[] | undefined = props.users?.map(
    (user): ITableUsersData => ({
      ...user,
      _id: user.id,
      onSmsClick: () => onSmsClick(user),
      onEmailClick: () => onEmailClick(user.id),
    }),
  );

  return (
    <Fragment>
      <div className="users-table__wrapper">
        <TypedTable
          columns={columns}
          data={tableUsersData}
          className="users-table"
        />
      </div>
      <UsersListEmpty
        users={props.users}
        loading={props.loading}
      >
        <Pagination
          total={props.total}
          page={props.page}
          minPage={props.minPage}
          maxPage={props.maxPage}
          limit={props.limit}
          limitsOptions={props.limitsOptions}
          onPrevClick={props.onPrevClick}
          onNextClick={props.onNextClick}
          onLimitChange={props.onLimitChange}
        />
      </UsersListEmpty>
    </Fragment>
  );
};
